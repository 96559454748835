import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { ListGroup, Container, Form, InputGroup, FormControl } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import BusLines from './BusLines';
import Schedule from './Schedule';
import Header from './Header';
import Footer from './Footer';
import Cookies from 'js-cookie';
import Arrets from './Arrets';
import BusStopDetails from './BusStopDetails';
import InfoTrafic from './InfoTrafic';
import LineTrafficDetails from './LineTrafficDetails';
import GestionTrafic from './admin/gestion_trafic';
import { FaArrowsRotate } from "react-icons/fa6";
import { SiSlashdot } from "react-icons/si"; // Importation de l'icône
import ArticleDetail from './ArticleDetail';
import Mentions from './mentions';
import PrivacyPolicy from './privacy_policy';
import Incident from './incident';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4'; // Importation de Google Analytics
import CreateArticle from './admin/getarticle'; // Assure-toi que le chemin correspond à l'emplacement réel du fichier
import Tarif from './tarif'; // Met 'tarif' en minuscule si le fichier est en minuscule
import { CgArrowsExchange } from 'react-icons/cg';



const App = () => {
  const [networks, setNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(Cookies.get('selectedNetwork') || '');
  const [isMaintenance, setIsMaintenance] = useState(false);

  useEffect(() => {
    // Initialisation de Google Analytics
    ReactGA.initialize('G-3XW783VZ82'); // Remplacez par votre ID de mesure Google Analytics

    // Suivi de la première visite
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

    // Vérifier l'état de maintenance du site
    axios.get('/checkMaintenance.php')
      .then(response => {
        console.log('Response from checkMaintenance.php:', response.data); // Log de la réponse
        setIsMaintenance(response.data.is_maintenance === 1); // Comparaison avec l'entier 1
      })
      .catch(error => {
        console.error('Error checking maintenance status:', error);
      });

    // Récupérer les réseaux avec les noms de départements
    axios.get('/getNetworks.php')
      .then(response => {
        if (Array.isArray(response.data)) {
          setNetworks(response.data);
        } else {
          console.error('Expected an array but got:', response.data);
        }
      })
      .catch(error => console.error('Error fetching networks:', error));
  }, []);

  // Intégration de Crisp
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = "c6623a41-1952-4d21-8a12-27dec46a7ce3";

  (function() {
    const d = document;
    const s = d.createElement("script");
    s.src = "https://client.crisp.chat/l.js";
    s.async = true;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();

  if (isMaintenance) {
    return (
      <Container className="mt-4">
        <h2>Site en maintenance</h2>
        <p>Le site est actuellement en maintenance. Merci de revenir plus tard.</p>
      </Container>
    );
  }

  return (
    <HelmetProvider>
      <Router>
        <Header />
        <Routes>
          <Route 
            path="/" 
            element={
              <Home 
                networks={networks} 
                selectedNetwork={selectedNetwork} 
                setSelectedNetwork={setSelectedNetwork} 
              />
            } 
          />
          <Route path="/network/:networkId" element={<BusLines />} />
          <Route path="/schedule/:networkId/:routeId/:directionId" element={<Schedule />} />
          <Route 
            path="/change-network" 
            element={
              <ChangeNetwork 
                networks={networks} 
                setSelectedNetwork={setSelectedNetwork} 
              />
            } 
          />
          <Route path="/arrets" element={<Arrets />} />
          <Route path="/network/:networkId/stop/:stopId" element={<BusStopDetails />} />
          <Route path="/infotrafic" element={<InfoTrafic />} />
          <Route path="/infotrafic" element={<LineTrafficDetails />} />
          <Route path="/admin/gestion-trafic" element={<GestionTrafic />} />
          <Route path="/admin/getarticle" element={<CreateArticle />} />  {/* Nouvelle route pour créer un article */}
          <Route path="/article_detail" element={<ArticleDetail />} />
          <Route path="/mentions" element={<Mentions />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/incident" element={<Incident />} />
          <Route path="/tarif" element={<Tarif />} /> {/* Route modifiée pour afficher la page tarif */}
        </Routes>
        <Footer />
      </Router>
    </HelmetProvider>
  );
};

const Home = ({ networks, selectedNetwork, setSelectedNetwork }) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  const handleNetworkSelect = (networkId) => {
    Cookies.set('selectedNetwork', networkId, { expires: 365 });
    setSelectedNetwork(networkId);
    navigate(`/network/${networkId}`);

    // Suivi du changement de réseau
    ReactGA.send({ hitType: 'event', eventCategory: 'Network', eventAction: 'Change', eventLabel: networkId });
  };

  useEffect(() => {
    if (selectedNetwork) {
      navigate(`/network/${selectedNetwork}`);
    }
  }, [selectedNetwork, navigate]);

  // Grouper les réseaux par département
  const groupedNetworks = networks.reduce((acc, network) => {
    const { departement_nom } = network;
    if (!acc[departement_nom]) {
      acc[departement_nom] = {
        name: departement_nom,
        networks: []
      };
    }
    acc[departement_nom].networks.push(network);

    // Trier les réseaux par ordre alphabétique de `leftText`
    acc[departement_nom].networks.sort((a, b) => {
      const [leftTextA] = (a.agency_name || '').split(' - ');
      const [leftTextB] = (b.agency_name || '').split(' - ');
      return leftTextA.localeCompare(leftTextB);
    });

    return acc;
  }, {});

  // Trier les départements par ordre alphabétique
  const sortedDepartments = Object.keys(groupedNetworks).sort();

  // Filtrer les réseaux en fonction du texte de recherche (inclut leftText et rightText)
  const filteredDepartments = sortedDepartments.filter(departement_nom => 
    groupedNetworks[departement_nom].networks.some(network => {
      const [leftText = '', rightText = ''] = (network.agency_name || '').split(' - ');
      return (
        leftText.toLowerCase().includes(searchText.toLowerCase()) || 
        rightText.toLowerCase().includes(searchText.toLowerCase())
      );
    })
  );

  return (
    <Container className="mt-4">
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        minHeight: '30vh',
        margin: '0 auto',
        padding: '0 15px'
      }}>
        <h2>Nouvelle Version <br />Bus Connect</h2><br />
        <p>
          Cette mise à jour rend l'application 10 fois plus fluide et apporte quelques nouveautés, dont de nouveaux réseaux&nbsp;de&nbsp;bus.<br />
          De plus, cette version corrige plusieurs bugs pour une meilleure expérience.<br />
          Prochainement, vous pourrez également utiliser la recherche d'itinéraire et explorer une carte globale interactive.
        </p>
      </div>

      <Form className="mb-4 text-center">
        <InputGroup style={{ borderRadius: '20px', overflow: 'hidden', border: '2px solid #0a78a4', maxWidth: '400px', minHeight: '50px', margin: '0 auto' }}>
          <InputGroup.Text style={{ backgroundColor: 'white', border: 'none', padding: '0 15px' }}>
            <FaSearch />
          </InputGroup.Text>
          <FormControl
            type="text"
            placeholder="Rechercher votre ligne de bus..."
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            style={{ border: 'none', boxShadow: 'none', paddingLeft: 0 }}
          />
        </InputGroup>
      </Form>

      {filteredDepartments.map(departement_nom => (
        <div key={departement_nom}>
          <h2 style={{ color: '#0a78a4', textTransform: 'uppercase', marginBottom: '20px' }}>
            <SiSlashdot style={{ marginRight: '10px', fontSize: '14px' }} />
            {groupedNetworks[departement_nom].name}
          </h2>
          <ListGroup style={{ border: '1px solid #000', borderRadius: '5px', overflow: 'hidden', margin: '0 0 20px 0' }}>
            {groupedNetworks[departement_nom].networks.filter(network => {
              const [leftText = '', rightText = ''] = (network.agency_name || '').split(' - ');
              return (
                leftText.toLowerCase().includes(searchText.toLowerCase()) || 
                rightText.toLowerCase().includes(searchText.toLowerCase())
              );
            }).map((network, index) => {
              const [leftText, rightText] = (network.agency_name || '').split(' - ');

              return (
                <div 
                  key={network.network_id} 
                  onClick={() => handleNetworkSelect(network.network_id)}
                  style={{ 
                    textDecoration: 'none', 
                    color: 'inherit',
                    cursor: 'pointer',
                    display: 'block'
                  }}
                >
                  <ListGroup.Item 
                    className="d-flex justify-content-between align-items-center" 
                    style={{ 
                      border: 'none', 
                      borderBottom: index !== groupedNetworks[departement_nom].networks.length - 1 ? '1px solid #000' : 'none', 
                      padding: '10px 15px',
                      backgroundColor: '#fff', 
                      color: '#000' 
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <img 
                        src={`/logo/${network.network_id}.png`} 
                        alt={leftText} 
                        className="agency-logo"
                      />
                      <span className="left-text">{leftText}</span>
                    </div>
                    <span className="right-text">{rightText}</span>
                  </ListGroup.Item>
                </div>
              );
            })}
          </ListGroup>
        </div>
      ))}
    </Container>
  );
};

const ChangeNetwork = ({ networks, setSelectedNetwork }) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  const handleNetworkSelect = (networkId) => {
    Cookies.set('selectedNetwork', networkId, { expires: 365 });
    setSelectedNetwork(networkId);
    navigate(`/network/${networkId}`);

    // Suivi du changement de réseau
    ReactGA.send({ hitType: 'event', eventCategory: 'Network', eventAction: 'Change', eventLabel: networkId });
  };

  // Grouper les réseaux par département
  const groupedNetworks = networks.reduce((acc, network) => {
    const { departement_nom } = network;
    if (!acc[departement_nom]) {
      acc[departement_nom] = {
        name: departement_nom,
        networks: []
      };
    }
    acc[departement_nom].networks.push(network);

    // Trier les réseaux par ordre alphabétique de `leftText`
    acc[departement_nom].networks.sort((a, b) => {
      const [leftTextA] = (a.agency_name || '').split(' - ');
      const [leftTextB] = (b.agency_name || '').split(' - ');
      return leftTextA.localeCompare(leftTextB);
    });

    return acc;
  }, {});

  // Trier les départements par ordre alphabétique
  const sortedDepartments = Object.keys(groupedNetworks).sort();

  // Filtrer les réseaux en fonction du texte de recherche (inclut leftText et rightText)
  const filteredDepartments = sortedDepartments.filter(departement_nom => 
    groupedNetworks[departement_nom].networks.some(network => {
      const [leftText = '', rightText = ''] = (network.agency_name || '').split(' - ');
      return (
        leftText.toLowerCase().includes(searchText.toLowerCase()) || 
        rightText.toLowerCase().includes(searchText.toLowerCase())
      );
    })
  );

  return (
    <Container className="mt-4">
      <div style={{ textAlign: 'center' }}>
        <div style={{ color: '#0a78a4', fontSize: '5em', marginBottom: '10px', marginTop: '-40px' }}>
        <CgArrowsExchange className="icon" /> 
        </div>
        <div style={{ marginBottom: '30px' }}>
          <h2 style={{ display: 'inline', marginRight: '10px' }}>
            Changer de réseau
          </h2>
        </div>
      </div>

      <Form className="mb-4 text-center">
        <InputGroup style={{ borderRadius: '20px', overflow: 'hidden', border: '2px solid #0a78a4', maxWidth: '400px', minHeight: '50px', margin: '0 auto' }}>
          <InputGroup.Text style={{ backgroundColor: 'white', border: 'none', padding: '0 15px' }}>
            <FaSearch />
          </InputGroup.Text>
          <FormControl
            type="text"
            placeholder="Rechercher votre réseau de bus..."
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            style={{ border: 'none', boxShadow: 'none', paddingLeft: 0 }}
          />
        </InputGroup>
      </Form>

      {filteredDepartments.map(departement_nom => (
        <div key={departement_nom}>
          <h2 style={{ color: '#0a78a4', textTransform: 'uppercase', marginBottom: '20px' }}>
            <SiSlashdot style={{ marginRight: '10px', fontSize: '14px'}} />
            {groupedNetworks[departement_nom].name}
          </h2>
          <ListGroup style={{ border: '1px solid #0a78a4', borderRadius: '5px', overflow: 'hidden', margin: '0 0 20px 0' }}>
            {groupedNetworks[departement_nom].networks.filter(network => {
              const [leftText = '', rightText = ''] = (network.agency_name || '').split(' - ');
              return (
                leftText.toLowerCase().includes(searchText.toLowerCase()) || 
                rightText.toLowerCase().includes(searchText.toLowerCase())
              );
            }).map((network, index) => {
              const [leftText, rightText] = (network.agency_name || '').split(' - ');

              return (
                <div 
                  key={network.network_id} 
                  onClick={() => handleNetworkSelect(network.network_id)}
                  style={{ 
                    textDecoration: 'none', 
                    color: 'inherit',
                    cursor: 'pointer',
                    display: 'block'
                  }}
                >
                  <ListGroup.Item 
                    className="d-flex justify-content-between align-items-center" 
                    style={{ 
                      border: 'none', 
                      borderBottom: index !== groupedNetworks[departement_nom].networks.length - 1 ? '1px solid #0a78a4' : 'none', 
                      padding: '10px 15px',
                      backgroundColor: '#fff', 
                      color: '#000' 
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <img 
                        src={`/logo/${network.network_id}.png`} 
                        alt={leftText} 
                        className="agency-logo"
                      />
                      <span className="left-text">{leftText}</span>
                    </div>
                    <span className="right-text">{rightText}</span>
                  </ListGroup.Item>
                </div>
              );
            })}
          </ListGroup>
        </div>
      ))}
    </Container>
  );
};

export default App;
