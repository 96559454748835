import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, FormControl, Modal, ListGroup, Spinner, InputGroup, Carousel, Button, Alert } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa"; 
import { GiBusStop } from "react-icons/gi";
import { MdArrowCircleLeft, MdArrowCircleRight } from "react-icons/md";
import './App.css';
import { Helmet } from 'react-helmet-async';


function BusLines() {
  const { networkId } = useParams();
  const navigate = useNavigate();
  const [lines, setLines] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedLine, setSelectedLine] = useState(null);
  const [directions, setDirections] = useState([]);
  const [stops, setStops] = useState([]);
  const [searchStopTerm, setSearchStopTerm] = useState('');
  const [loadingStops, setLoadingStops] = useState(false);
  const [articles, setArticles] = useState([]);
  const [loadingArticles, setLoadingArticles] = useState(true);

  useEffect(() => {
    axios.get(`/getBusLines.php?network_id=${networkId}`)
      .then(response => {
        if (Array.isArray(response.data)) {
          setLines(response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching bus lines:', error);
      });
  }, [networkId]);

  useEffect(() => {
    if (searchStopTerm.length > 0) {
      setLoadingStops(true);
      axios.get(`/getBusStops.php?network_id=${networkId}&query=${searchStopTerm}`)
        .then(response => {
          if (Array.isArray(response.data)) {
            const decodedStops = response.data.map(stop => ({
              ...stop,
              stop_name: decodeURIComponent(stop.stop_name)
            }));
            setStops(decodedStops);
          } else {
            setStops([]);
          }
        })
        .catch(error => {
          console.error('Error fetching bus stops:', error);
          setStops([]);
        })
        .finally(() => {
          setLoadingStops(false);
        });
    } else {
      setStops([]);
      setLoadingStops(false);
    }
  }, [searchStopTerm, networkId]);

  useEffect(() => {
    axios.get(`/getBusLines.php?network_id=${networkId}&get_articles=true`)
      .then(response => {
        if (Array.isArray(response.data)) {
          setArticles(response.data);
        } else {
          setArticles([]);
        }
      })
      .catch(error => {
        console.error('Error fetching articles:', error);
        setArticles([]);
      })
      .finally(() => {
        setLoadingArticles(false);
      });
  }, [networkId]);

  const normalizeString = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  };

  const filteredLines = lines.filter(line =>
    normalizeString(line.route_short_name).includes(normalizeString(searchTerm)) ||
    normalizeString(line.route_long_name).includes(normalizeString(searchTerm))
  );

  const filteredStops = stops.filter(stop =>
    normalizeString(stop.stop_name).includes(normalizeString(searchStopTerm))
  );

  const handleShowModal = (line) => {
    setSelectedLine(line);
    axios.get(`/getBusLines.php?route_id=${line.route_id}&network_id=${networkId}`)
      .then(response => {
        setDirections(response.data);
        setShowModal(true);
      })
      .catch(error => {
        console.error('Error fetching directions:', error);
      });
  };

  const handleSelectDirection = (direction_id) => {
    navigate(`/schedule/${networkId}/${selectedLine.route_id}/${direction_id}`);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedLine(null);
    setDirections([]);
  };

  const getTextColor = (backgroundColor) => {
    if (!backgroundColor || backgroundColor.length !== 6) return 'black';
    const r = parseInt(backgroundColor.substring(0, 2), 16);
    const g = parseInt(backgroundColor.substring(2, 4), 16);
    const b = parseInt(backgroundColor.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 186 ? 'black' : 'white';
  };

  return (
    <Container className="my-1 d-flex align-items-center justify-content-center">
      <Helmet>
  <title>Bus Connect - {networkId}</title>
</Helmet>
      <div className="w-100">
        <img src={`/logo/${networkId}.png`} alt={`${networkId} Logo`} className="responsive-logo mx-auto d-block" style={{ maxHeight: '100px'}}/>
        
        <Form className="mb-4 text-center">
          <InputGroup style={{ borderRadius: '20px', overflow: 'hidden', border: '2px solid #0a78a4', maxWidth: '400px',  minHeight: '50px', margin: '0 auto' }}>
            <InputGroup.Text style={{ backgroundColor: 'white', border: 'none', padding: '0 15px' }}>
               <GiBusStop />
            </InputGroup.Text>
            <FormControl
              type="text"
              placeholder="Rechercher votre arrêt de bus..."
              onChange={e => setSearchStopTerm(e.target.value)}
              style={{ border: 'none', boxShadow: 'none', paddingLeft: 0 }}
            />
          </InputGroup>
          {loadingStops && (
            <div className="text-center mt-2">
              <Spinner animation="border" role="status" className="d-block mx-auto custom-spinner">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          {Array.isArray(filteredStops) && filteredStops.length > 0 && !loadingStops && (
            <ListGroup className="mt-2 list-group-arrets">
              {filteredStops.map(stop => {
                const uniqueRoutesAndColors = stop.routesAndColors.filter(
                  (value, index, self) =>
                    index === self.findIndex((t) => t.route === value.route && t.color === value.color)
                );
                return (
                  <ListGroup.Item
                    key={stop.stop_id}
                    className="stop-item"
                    onClick={() => navigate(`/network/${networkId}/stop/${stop.stop_id}`)}
                  >
                    <div className="d-flex flex-column align-items-start">
                      <strong>{stop.stop_name}</strong>
                      <div className="badge-container">
                        {uniqueRoutesAndColors.map(({ route, color }) => (
                          <span
                            key={route}
                            style={{
                              textTransform: 'uppercase',
                              backgroundColor: `#${color}`,
                              color: getTextColor(color),
                              fontWeight: 'bold',
                              borderRadius: '5px',
                              padding: '2px 5px',
                              margin: '2px',
                              display: 'inline-block'
                            }}
                          >
                            {route}
                          </span>
                        ))}
                      </div>
                    </div>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          )}
        </Form>

        <Form className="mb-4 text-center">
          <InputGroup style={{ borderRadius: '20px', overflow: 'hidden', border: '2px solid #0a78a4', maxWidth: '400px',  minHeight: '50px', margin: '0 auto' }}>
            <InputGroup.Text style={{ backgroundColor: 'white', border: 'none', padding: '0 15px' }}>
            <FaSearch />
            </InputGroup.Text>
            <FormControl
              type="text"
              placeholder="Rechercher votre ligne de bus..."
              onChange={e => setSearchTerm(e.target.value)}
              style={{ border: 'none', boxShadow: 'none', paddingLeft: 0 }}
            />
          </InputGroup>
        </Form>

        <Row className="justify-content-center">
          <Col md="auto">
            <ListGroup>
              {filteredLines.map(line => {
                const isDisabled = line.etat === 0;
                return (
                  <ListGroup.Item
                    key={line.route_id}
                    className={`d-flex align-items-center ${isDisabled ? 'disabled-line' : ''}`}
                    onClick={!isDisabled ? () => handleShowModal(line) : null}
                    style={{
                      cursor: isDisabled ? 'not-allowed' : 'pointer',
                      backgroundColor: `#${line.route_color}`,
                      color: getTextColor(line.route_color),
                      opacity: isDisabled ? 0.5 : 1,
                    }}
                  >
                    <div
                      className="line-color-box"
                      style={{
                        color: getTextColor(line.route_color),
                      }}
                    >
                      <span className="line-short-name">{line.route_short_name}</span>
                    </div>
                    <div className="line-long-name">
                      <span>{line.route_long_name}</span>
                    </div>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Col>
        </Row>

        {/* Section Articles */}
        <Row className="mt-5">
          <Col md="12">
            <h3>Actualités</h3>
            {loadingArticles ? (
              <Spinner animation="border" role="status" className="d-block mx-auto custom-spinner">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : articles.length === 0 ? (
              <Alert variant="danger" className="text-center">
                Aucune actualité sur ce réseau
              </Alert>
            ) : (
              <Carousel
                nextIcon={<MdArrowCircleRight size={40} color="#0a78a4" />}
                prevIcon={<MdArrowCircleLeft size={40} color="#0a78a4" />}
                indicators={false}  // Pour masquer les indicateurs du carousel
              >
                {articles.map(article => (
                  <Carousel.Item key={article.id}>
                    <img
                      className="d-block w-100"
                      src={article.image_url}
                      alt={article.title}
                    />
                    <Carousel.Caption className="d-flex flex-column justify-content-end align-items-center" style={{ bottom: '0', paddingBottom: '20px' }}>
                      <h3 style={{ marginBottom: '10px' }}>{article.title}</h3>
                      <Button variant="primary" onClick={() => navigate(`/article_detail?id=${article.id}`)} style={{ width: '100%' }}>
                        En savoir plus
                      </Button>
                    </Carousel.Caption>
                  </Carousel.Item>
                ))}
              </Carousel>
            )}
          </Col>
        </Row>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedLine ? selectedLine.route_long_name : ''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.entries(directions).map(([direction_id, terminus]) => (
              <div
                key={direction_id}
                style={{
                  backgroundColor: `#${selectedLine.route_color}`,
                  borderColor: `#${selectedLine.route_color}`,
                  width: '100%',
                  padding: '10px',
                  borderRadius: '4px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  margin: '8px',
                  userSelect: 'none',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: getTextColor(selectedLine.route_color),
                }}
                className="m-2"
                onClick={() => handleSelectDirection(direction_id)}
              >
                {terminus}
              </div>
            ))}
          </Modal.Body>
        </Modal>
      </div>
    </Container>
  );
}

export default BusLines;
